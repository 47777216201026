<template>
  <v-row
    no-gutters
    class="gestionduclub__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" sm="4" lg="4">
      <v-row no-gutters class="gestionduclub__dashboard-sidebar p-3">
        <v-col cols="12">
          <Avatar
            classes="__xl"
            textEditLink="Modifier votre fanion"
            textHeader="Modifier votre fanion"
            :clubID="currentStructureId"
            :userID="null"
            :changeLink="canChangeAvatar"
          ></Avatar>

          <!-- // TODO COMPONENTS FOR SRUCTURE-->
        </v-col>

        <v-col cols="12">
          <p class="text-center text-uppercase my-2">
            {{ structure.NomStructure }}
          </p>
          <p v-if="structure.ID_CodeFederal" class="text-center">(N°{{ structure.ID_CodeFederal }})</p>
        </v-col>

        <v-col cols="12">
          <p class="text-center">Informations générales</p>
          <p class="text-center caption">{{ structure.INFO_Presentation }}</p>
        </v-col>

        <v-col
          v-if="
            currentStructureType.SlugTypeStructure != 'comite_territorial' &&
            currentStructureType.SlugTypeStructure != 'ligue'
          "
          cols="12"
          class="d-flex flex-wrap justify-center"
        >
          <template v-for="(item, index) in clubActivite">
            <v-chip :key="index" class="ma-2" color="primary" small outlined>
              {{ item.LIST_ActiviteType.NomActivite }}
              <v-icon
                v-if="item.EST_ActivitePrincipale"
                size="medium"
                color="yellow"
                >mdi-star</v-icon
              >
            </v-chip>
          </template>
        </v-col>

        <v-col cols="12" class="d-flex justify-center align-center">
          <template v-for="item in reseauxSociaux">
            <v-btn
              icon
              class="title mx-1"
              :key="item.id"
              :class="`color-${item.LIST_UrlType.IMG_CheminLogo}`"
              :href="item.Url"
              target="_blank"
            >
              <font-awesome-icon
                :icon="{
                  prefix:
                    item.LIST_UrlType.NomTypeUrl === 'Site web' ? 'fa' : 'fab',
                  iconName: item.LIST_UrlType.IMG_CheminLogo,
                }"
                v-if="item.LIST_UrlType.IMG_CheminLogo"
              />
            </v-btn>
          </template>
        </v-col>

        <v-col cols="12" class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'information_de_la_structure__presentation' }"
            class="btn btn-primary"
          >
            Modifier
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" sm="8" lg="8" class="p-4 gestionduclub__dashboard-content">
      <v-row v-if="structureType == 'ligue'">
        <v-col cols="6">
          <dashboardCard
            module_name="information_de_la_structure__presentation"
            title_card="Presentation de la ligue"
            ID_User="currentUserId"
            auth
          />
        </v-col>

        <v-col cols="6">
          <dashboardCard
            module_name="gestion_des_saisons__informations_generales"
            title_card="Gestion de la saison"
            ID_User="currentUserId"
            auth
          />
        </v-col>
        <v-col cols="6">
          <dashboardCard
            module_name=""
            title_card="Tableaux de bord"
            ID_User="currentUserId"
            auth
            isComingSoon
          />
        </v-col>
        <v-col cols="6">
          <v-card
            height="100%"
            width="100%"
            style="max-height: 450px;"
            class="p-3 d-flex flex-column justify-center"
          >
            <v-card-text class="text-center pb-0">
              <p class="subtitle-1 font-weight-bold text-uppercase text--primary">
                Gestion des utilisateurs et rôles
              </p>
            </v-card-text>

            <v-card-actions class="justify-center">
              <a
                :href="linkGestionUtilisateurs"
                class="subtitle-2 font-weight-light"
              >
                <v-btn icon exact>
                  <v-icon color="#00abe7" size="28">mdi-arrow-right</v-icon>
                </v-btn>
              </a>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- <v-col cols="6">
          <card
            module_name="information_de_la_structure__organigramme"
            title_card="Membres du bureau"
            ID_User="currentUserId"
          />
        </v-col> -->
      </v-row>

      <v-row v-else-if="structureType == 'comite_territorial'">
        <v-col cols="6">
          <dashboardCard
            module_name="information_de_la_structure__presentation"
            title_card="Presentation du CT"
            ID_User="currentUserId"
            auth
          />
        </v-col>

        <v-col cols="6">
          <dashboardCard
            module_name="gestion_des_saisons__informations_generales"
            title_card="Gestion de la saison"
            ID_User="currentUserId"
            auth
          />
        </v-col>

        <v-col cols="6">
          <dashboardCard
            module_name="information_de_la_structure__organigramme"
            title_card="Membres du bureau"
            ID_User="currentUserId"
            auth
          />
        </v-col>
        <v-col cols="6">
          <v-card
            height="100%"
            width="100%"
            style="max-height: 450px;"
            class="p-3 d-flex flex-column justify-center"
          >
            <v-card-text class="text-center pb-0">
              <p class="subtitle-1 font-weight-bold text-uppercase text--primary">
                Gestion des utilisateurs et rôles
              </p>
            </v-card-text>

            <v-card-actions class="justify-center">
              <a
                :href="linkGestionUtilisateurs"
                class="subtitle-2 font-weight-light"
              >
                <v-btn icon exact>
                  <v-icon color="#00abe7" size="28">mdi-arrow-right</v-icon>
                </v-btn>
              </a>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-else-if="structureType == 'service_federal_ffme'">
        <v-col cols="6">
          <dashboardCard
            module_name="information_de_la_structure__presentation"
            title_card="Présentation de la FFME"
            ID_User="currentUserId"
            :class="{
              card__overlined: can('information_de_la_structure__presentation'),
            }"
            auth
          />
        </v-col>

        <v-col cols="6">
          <dashboardCard
            module_name="gestion__des__déclarations__de__voyages"
            title_card="Déclaration des voyages"
            ID_User="currentUserId"
            :class="{
              card__overlined: can('gestion__des__déclarations__de__voyages'),
            }"
            auth
          />
        </v-col>
        <v-col cols="6">
          <v-card
            height="100%"
            width="100%"
            style="max-height: 450px;"
            class="p-3 d-flex flex-column justify-center"
          >
            <v-card-text class="text-center pb-0">
              <p class="subtitle-1 font-weight-bold text-uppercase text--primary">
                Gestion des utilisateurs et rôles
              </p>
            </v-card-text>

            <v-card-actions class="justify-center">
              <a
                :href="linkGestionUtilisateurs"
                class="subtitle-2 font-weight-light"
              >
                <v-btn icon exact>
                  <v-icon color="#00abe7" size="28">mdi-arrow-right</v-icon>
                </v-btn>
              </a>
            </v-card-actions>
          </v-card>
        </v-col>

        <!-- <v-col cols="6">
          <card
            module_name="information_de_la_structure__organigramme"
            title_card="Membres du bureau"
            ID_User="currentUserId"
          />
        </v-col> -->
      </v-row>

      <v-row v-else>
        <v-col
          v-if="
            isNextSeasonOpen
            && !clubIsAffilierForNextSeason
            && isNotTypeMA
            && isAdmin
          "
          cols="6"
        >
          <dashboardCard
            module_name="affiliations__informations_generales"
            :urlParams="{ seasonId: nextSaison.id }"
            :title_card="`RÉ-AFFILIER MA STRUCTURE POUR LA SAISON ${nextSaison.id}`"
            class="affiliation--warning"
          />

        </v-col>

        <v-col
          v-if="
            !clubIsAffilier && isNotTypeMA
            && isAdmin
          "
          cols="6"
        >
          <dashboardCard
            module_name="affiliations__informations_generales"
            :urlParams="{ seasonId: currentSaisonId }"
            :title_card="`RÉ-AFFILIER MA STRUCTURE POUR LA SAISON ${currentSaisonId}`"
            class="affiliation--warning"
          />
        </v-col>

        <v-col cols="6">
          <dashboardCard
            module_name="information_de_la_structure__presentation"
            :title_card="titleDashboard"
            :class="{
              card__overlined: can('information_de_la_structure__presentation'),
            }"
            auth
          />
        </v-col>

        <v-col cols="6">
          <dashboardCard
            module_name="information_de_la_structure__tableau_de_bord"
            title_card="Tableau de bord"
            :class="{
              card__overlined: can(
                'information_de_la_structure__tableau_de_bord'
              ),
            }"
            isComingSoon
            auth
          />
        </v-col>

        <v-col cols="6">
          <dashboardCard
            module_name="information_de_la_structure__gestion_des_documents"
            title_card="Ressources documentaires"
            subtitle_card="Actuellement disponible sur l'ancien intranet, prochainement disponible sur MyFFME"
            :class="{
              card__overlined: can(
                'information_de_la_structure__gestion_des_documents'
              ),
            }"
            custom_link="https://www.montagne-escalade.com/site/BO/IN_Sommaire.php"
            auth
          />
        </v-col>

        <v-col cols="6">
          <dashboardCard
            module_name="information__historique_affiliations"
            title_card="Historique des affiliations"
            subtitle_card="Retrouvez les attestations d'affiliation et d'assurance"
            target="_blank"
            auth
          />
        </v-col>
        <v-col cols="6">
          <v-card
            height="100%"
            width="100%"
            style="max-height: 450px;"
            class="p-3 d-flex flex-column justify-center"
          >
            <v-card-text class="text-center pb-0">
              <p class="subtitle-1 font-weight-bold text-uppercase text--primary">
                Gestion des utilisateurs et rôles
              </p>
            </v-card-text>

            <v-card-actions class="justify-center">
              <a
                :href="linkGestionUtilisateurs"
                class="subtitle-2 font-weight-light"
              >
                <v-btn icon exact>
                  <v-icon color="#00abe7" size="28">mdi-arrow-right</v-icon>
                </v-btn>
              </a>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Avatar from "@/components/Avatar.vue";
import RoleConstants from "@/constants/roles";
import DashboardCard from "@/components/Common/DashboardCard";

export default {
  components: { Avatar, DashboardCard },
  data: () => ({
    loading: true,
    affiliation: null,
    affiliationNextSeason: null,
    idTypeStructure: 6,
    isCreated: false,
  }),
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === null) {
        vm.isCreated = true;
      }
    });
  },
  async mounted() {
    if (this.isCreated) {
      return;
    }

    await this.setAppTitle({ title: "Gestion de la structure" });
    await this.getStructureActivite();
    await this.getReseauxUrl();

    this.affiliation = await this.getStructureAffiliation({
      ID_Structure: this.currentStructureId,
      ID_Saison: this.currentSaisonId,
    });

    if (!this.affiliation) {
      this.affiliation = await this.insertStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.currentSaisonId,
      });
    }

    this.affiliationNextSeason = await this.getStructureAffiliation({
      ID_Structure: this.currentStructureId,
      ID_Saison: this.nextSaison.Annee,
    });

    if (!this.affiliationNextSeason) {
      this.affiliationNextSeason = await this.insertStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.nextSaison.Annee,
      });
    }

    console.log("this.affiliation =====>>", this.affiliation);

    this.loading = false;
    this.isCreated = true;
  },

  async beforeUpdate() {
    if (!this.clubActivite) {
      this.loading = true;
      // await this.getStructureActivite();
      this.loading = false;
    }

    if (!this.reseauxSociaux) {
      this.loading = true;
      await this.getReseauxUrl();
      this.loading = false;
    }
  },

  methods: {
    ...mapActions("structure", [
      "getStructureActivite",
      "getReseauxUrl",
      "getStructureAffiliation",
      "insertStructureAffiliation",
    ]),
    ...mapActions("app", ["setAppTitle"]),
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "currentStructureId",
      "currentStructureType",
    ]),
    ...mapGetters("saisons", ["saisons", "currentSaisonId", "nextSaison"]),
    ...mapGetters("user", ["can", "getRole"]),
    isNotTypeMA() {
      return this.currentStructure.STR_Structure.ID_TypeStructure !== 6;
    },

    clubIsAffilier() {
      console.log("this.affiliation clubIsAffilier", this.affiliation);
      if (!this.affiliation) return false;
      return (
        this.affiliation.LIST_EtapeAffiliation.SlugEtapeAffiliation ==
        "pre_affiliation_termine"
      );
    },

    clubIsAffilierForNextSeason() {
      if (!this.affiliationNextSeason) return false;

      return (
        this.affiliationNextSeason.ID_EtapeAffiliation === 3
      );
    },

    isNextSeasonOpen() {
      return this.nextSaison.ID_EtapeSaison >= 3;
    },

    structureType() {
      return this.currentStructure.STR_Structure.LIST_StructureType
        .SlugTypeStructure;
    },

    clubActivite() {
      if (!this.loading) return this.currentStructure.ACTI_ActivitePratiquee;
      return null;
    },

    structure() {
      return this.currentStructure.STR_Structure;
    },

    reseauxSociaux() {
      if (!this.loading) return this.currentStructure.DOC_Url;
      return null;
    },

    canChangeAvatar() {
      return this.currentStructure.STR_Structure.LIST_StructureType.EST_Club;
    },

    titleDashboard() {
      if (
        this.currentStructure?.STR_Structure?.LIST_StructureType?.id ===
        this.idTypeStructure
      ) {
        return "Informations de ma structure";
      } else {
        return "Informations de mon club";
      }
    },

    isAdmin() {
      if (
        [
          RoleConstants.ClubAdministrateur,
          RoleConstants.LigueAdministrateur,
          RoleConstants.Administrateur,
          RoleConstants.EtablissementAffilieAdministrateur,
          RoleConstants.AdministrateurVieTerritoriale,
          RoleConstants.MembreAssocieAdministrateur,
          RoleConstants.AdministrateurComptabilite,
          RoleConstants.CTAdministrateur,
        ].includes(this.getRole?.id ?? 0)
      ) {
        return true;
      }

      return false;
    },
    linkGestionUtilisateurs() {
      return `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-structure/utilisateurs?structureId=${this.currentStructureId}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },

    // gestionName() {
    //   let type = this.currentStructure.STR_Structure.LIST_StructureType;
    //   if (type && type.NomTypeStructure) {
    //     return "Gestion " + type.NomTypeStructure;
    //   } else {
    //     return "Gestion de la structure";
    //   }
    // },
  },
};
</script>

<style lang="scss">
.gestionduclub__dashboard-layout {
  position: relative;
  &.loading {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
.card__overlined button {
  &:hover {
    color: #007bff !important;
  }
}

.affiliation--warning {
  background-color: #fdf0e6 !important;

  .v-card__text {
    p.subtitle-1 {
      color: #5f2b01 !important;
    }
  }

  .v-card__actions {
    a.subtitle-2 {
      i {
        color: #E65100 !important;
      }
    }
  }
}
</style>
