<template>
  <ul class="d-flex flex-column justify-space-between pl-0">
    <liNav
    title="Etape 1 - Saisie des informations générales"
    module_name="affiliations__informations_generales"
    auth
    />
    <span v-if="!step1IsValid">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <liNav
              title="Etape 2 - Saisie des activités à joindre"
              module_name="affiliations__activites_du_club"
              auth
              :disabled="!step1IsValid"
            />
          </span>
        </template>
        <span>Valider l'étape précédente pour pouvoir accéder à cette étape</span>
      </v-tooltip>
    </span>
    <liNav
      v-else
      title="Etape 2 - Saisie des activités à joindre"
      module_name="affiliations__activites_du_club"
      auth
    />
    <span v-if="!step1IsValid || !step2IsValid">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <liNav
              title="Etape 3 - Saisie des documents"
              module_name="affiliations__documents_a_joindre"
              auth
              :disabled="!step1IsValid || !step2IsValid"
            />
          </span>
        </template>
        <span>Valider l'étape précédente pour pouvoir accéder à cette étape</span>
      </v-tooltip>
    </span>
    <liNav
      v-else
      title="Etape 3 - Saisie des documents"
      module_name="affiliations__documents_a_joindre"
      auth
    />
    <span v-if="!step1IsValid || !step2IsValid || !step3IsValid">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <liNav
              title="Etape 4 - Saisie des salariés"
              module_name="affiliations__gestion_des_salaries"
              auth
              :disabled="!step1IsValid || !step2IsValid || !step3IsValid"
            />
          </span>
        </template>
        <span>Valider l'étape précédente pour pouvoir accéder à cette étape</span>
      </v-tooltip>
    </span>
    <liNav
      v-else
      title="Etape 4 - Saisie des salariés"
      module_name="affiliations__gestion_des_salaries"
      auth
    />
    <span v-if="!step1IsValid || !step2IsValid || !step3IsValid || !step4IsValid">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <liNav
              title="Etape 5 - Synthèse et validation"
              module_name="affiliations__tableau_de_synthese"
              auth
              :disabled="!step1IsValid || !step2IsValid || !step3IsValid || !step4IsValid"
            />
          </span>
        </template>
        <span>Valider l'étape précédente pour pouvoir accéder à cette étape</span>
      </v-tooltip>
    </span>
    <liNav
      v-else
      title="Etape 5 - Synthèse et validation"
      module_name="affiliations__tableau_de_synthese"
      auth
    />
  </ul>
</template>

<script>
import liNav from "@/components/Common/Navigation__item";
import { mapActions, mapGetters } from "vuex";
import StructureType from "@/constants/structureType";

export default {
  components: { liNav },

  data: () => ({
    selectedSeason: null,
    step1IsValid: false,
    step2IsValid: false,
    step3IsValid: false,
    step4IsValid: false,
    structureDisabilities: [],
    affiliations: null,
  }),

  async created() {
    this.selectedSeason = this.saisons.find((season) => season.id === parseInt(this.$route.params.seasonId, 10));
    this.affiliations = await this.getStructureAffiliation({
      ID_Structure: this.currentStructureId,
      ID_Saison: parseInt(this.selectedSeason.id, 10),
    });

    this.structureDisabilities = await this.getStructureDisabilities(this.currentStructureId);
    this.structureDisabilities = this.structureDisabilities.map(
      (disability) => disability.id_disability
    );

    this.validateStep1();
    this.validateStep2();
    this.validateStep3();
    this.validateStep4();
  },

  computed: {
    ...mapGetters("structure", [
      "getStructure",
      "currentStructureId",
      "currentStructure"
    ]),
    ...mapGetters("saisons", ["saisons"]),
    isStructureEA() {
      return this.currentStructure.STR_Structure.ID_TypeStructure === StructureType.ClubEtablissementAffilie;
    },
  },

  methods: {
    ...mapActions("basics", [
      "getListTypeFonctions",
    ]),
    ...mapActions("utilisateurs", [
      "getListDirigeants",
    ]),
    ...mapActions("structure", [
      "getStructureAffiliation",
      "getStructureActivite",
      "getStructureType",
      "getStructureDisabilities",
    ]),

    async validateStep1() {
      let fonctiontList = await this.getListTypeFonctions();
      let structure = await this.getStructure(this.currentStructureId);
      let payload = {
        where: {
          EST_Actif: {
            _eq: true
          },
          ID_Structure: {
            _eq: this.currentStructureId
          }
        }
      };
      let utilisateurs = await this.getListDirigeants(payload);

      let correspondant = utilisateurs.find((user) =>
          user.ID_Utilisateur ==
          structure.STR_Structure.ID_NomCorrespondant
      );

      let presidentType = fonctiontList.find(
        (fct) => fct.SlugFonction == "president"
      );

      if (
        correspondant &&
        structure.STR_Structure.ID_AdresseCorrespondance &&
        (utilisateurs.some((user) => user.ID_Fonction == presidentType.id) || this.isStructureEA)
      ) {
        this.step1IsValid = true;
        return true;
      }

      this.step1IsValid = false;
      return false;
    },

    async validateStep2() {
      let activities = await this.getStructureActivite(this.currentStructureId);

      const isThereMainActivity = activities.some((activity) => activity.EST_ActivitePrincipale === true);
      if (activities.length === 0 || !isThereMainActivity) {
        this.step2IsValid = false;

        return false;
      }

      if (
        this.affiliations.CLUB_EST_AcceuillantHandicape !== null &&
        ((this.affiliations.CLUB_EST_AcceuillantHandicape === true && this.structureDisabilities.length > 0) || this.affiliations.CLUB_EST_AcceuillantHandicape === false) &&
        this.affiliations.CLUB_EST_AcceuillantMineur !== null &&
        this.affiliations.CLUB_AcceuillantMineurAge !== null &&
        this.affiliations.CLUB_EST_AcceptePassSportCulture !== null &&
        this.affiliations.CLUB_EST_PretMateriel !== null &&
        this.affiliations.CLUB_EST_ConventionAllocationFamilliale !== null &&
        this.affiliations.CLUB_EST_AccepteChequesVacances !== null &&
        this.affiliations.CLUB_EST_AccepteCouponSport !== null &&
        this.affiliations.CLUB_EST_PreparePasseport !== null
      ) {
        this.step2IsValid = true;

        return true;
      }

      this.step2IsValid = false;

      return false;
    },

    async validateStep3() {
      let affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSeason.id,
      });

      let structureType = await this.getStructureType({
        ID_Structure: this.currentStructureId,
      });
      structureType = structureType.LIST_StructureType;

      if (structureType.EST_Club) {
        if (
          structureType.SlugTypeStructure == "club_association"
          || structureType.SlugTypeStructure == "club_section_association"
        ) {
          if (affiliation.ID_DocCompteRenduAg) {
            this.step3IsValid = true;
            return true;
          }
        }

        if (structureType.SlugTypeStructure === 'club_etablissement_affilie') {
          if (
            affiliation.ID_DocDiplomeEncadrant
            && affiliation.ID_DocCarteProfessionnelle
            && affiliation.ID_DocAttestationAssurance
          ) {
            this.step3IsValid = true;
            return true;
          }
        }
      }

      if (
        affiliation.ID_DocCompteRenduAg
        && affiliation.ID_DocStatus
      ) {
        this.step3IsValid = true;
        return true;
      }

      this.step3IsValid = false;
      return false;
    },

    async validateStep4() {
      let affiliation = await this.getStructureAffiliation({
        ID_Structure: this.currentStructureId,
        ID_Saison: this.selectedSeason.id,
      });

      // nb hours sal need to be setted.
      if (affiliation.SAL_EquivalentTempsPlein == null) {
        this.step4IsValid = false;
        return false;
      }

      // if nb hours ===0 then collecteur formation pro isnt needed
      if (+affiliation.SAL_EquivalentTempsPlein == 0) {
        this.step4IsValid = true;
        return true;
      } else {
        if (affiliation.ID_CollecteurFormationProfessionnelle) {
          this.step4IsValid = true;
          return true;
        }
      }

      this.step4IsValid = false;
      return false;
    },
  },
};
</script>
